import SupperlierTesting from '../components/supperlier-testing.vue'
import Amountinput from '@/components/input/amount-input/amount-input'
import SupplierCascader from '../components/supplier-cascader'
import { getDictLists } from '@/filters/fromDict'
import { formatDate } from '@/utils/auth/common'
// import BaseSelect from '@/components/common/base-select/base-select.vue'

// 基础信息
export const basicFromConfig = (content) => {
  /**
   * 供应商名字
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validatePassName = (rule, value, callback) => {
    const reg = !/^[\u4e00-\u9fa5a-zA-Z]/gi.test(value)
    if (!value) {
      return callback(new Error(`${rule.title}不能为空`))
    } else if (reg) {
      return callback(new Error(`${rule.title}只能输入中文和英文`))
    } else {
      callback()
    }
  }
  /**
   * 社会信用代码校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validatecreditCode = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入社会信用代码'))
    } else if (value.length < 18) {
      return callback(new Error('请输入正确统一社会信用代码'))
    } else {
      callback()
    }
  }
  // const newvalidatecreditCode = (rule, value, callback) => {
  //   if (!value) {
  //     return callback()
  //   } else {
  //     callback()
  //   }
  // }
  /**
   * 工商注册日期校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validateregisterTime = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请选择工商注册日期'))
    } else if (content.baseQueryParas.foundTime && content.baseQueryParas.foundTime < value) {
      return callback(new Error('请选择正确时间并且工商注册日期不能大于成立时间'))
    } else {
      callback()
    }
  }
  /***
   * 成立时间校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validatefoundTime = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请选择成立时间'))
    } else if (content.baseQueryParas.registerTime && content.baseQueryParas.registerTime > value) {
      return callback(new Error('请选择正确时间并且成立时间不能小于工商注册日期'))
    } else {
      callback()
    }
  }
  /**
   * 输入简码校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validataEnterpriseShortCode = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入简码'))
    } else if (!/^[a-zA-Z]+$/mgi.test(value)) {
      return callback(new Error('简码只能输入英文'))
    } else {
      callback()
    }
  }
  /***
   * 简称校验
   * @param rule
   * @param value
   * @param callback
   * @returns {*}
   */
  const validataEnterpriseShortName = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入简称'))
    } else if (!/[\u4e00-\u9fa5]/mgi.test(value)) {
      return callback(new Error('简称只能输入中文'))
    } else {
      callback()
    }
  }
  // 所属城市校验
  const validateLocationCity = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入所在城市'))
    } else if (Array.isArray(value) && !value[0]) {
      return callback(new Error('请输入所在城市'))
    } else {
      callback()
    }
  }
  // 公司电话校验
  const validatePhone = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入公司电话'))
    } else if (!/[^0-9()（）-]/mgi.test(value)) {
      return callback()
    } else {
      callback(new Error('请输入正确的公司电话'))
    }
  }

  return [
    {
      label: '企业名称：',
      prop: 'enterpriseName',
      rules: [{
        required: true,
        title: '企业名称',
        validator: validatePassName,
        trigger: ['change', 'blur']
      }],
      attrs: {
        placeholder: '请输入',
        enterpriseName: content.baseQueryParas.enterpriseName,
        keyId: content.baseQueryParas.keyId
      },
      span: 24,
      tag: SupperlierTesting,
      on: {
        getSupplier: (data) => {
          if (data) {
            // 检测成功数据回填
            if (data.companyInfo) {
              content.$set(data.companyInfo, 'locationCity', [
                data.companyInfo.manageProvince ? data.companyInfo.manageProvince : content.baseQueryParas.manageProvince,
                data.companyInfo.manageCity ? data.companyInfo.manageCity : content.baseQueryParas.manageCity
              ])
            }
            content.baseQueryParas = data.companyInfo ? data.companyInfo : content.baseQueryParas
            content.easQueryParas = data.easInfoList ? data.easInfoList : content.easQueryParas
            content.personnelData = data.personnelList ? Object.assign({}, { records: data.personnelList }) : content.personnelData
            content.easfiledata = data.easfileList ? data.easfileList : content.easfiledata
            content.easFileList = data.easfileList ? data.easfileList : content.easFileList
            content.accountData = data.bankAccountList ? Object.assign({}, { records: data.bankAccountList }) : content.accountData
            content.editTable()
            // 人员信息and账户信息能修改
            if (data.bankAccountList) {
              if (data.bankAccountList.records) {
                data.bankAccountList.records.forEach(item => {
                  item.addtype = true
                })
              } else {
                data.bankAccountList.forEach(item => {
                  item.addtype = true
                })
              }
            }
            if (data.personnelList) {
              if (data.personnelList.records) {
                data.personnelList.records.forEach(item => {
                  if (item.dictId) {
                    item.dictId = item.dictId.split(',')
                  }
                  item.addtype = true
                })
              } else {
                data.personnelList.forEach(item => {
                  if (item.dictId) {
                    item.dictId = item.dictId.split(',')
                  }
                  item.addtype = true
                })
              }
            }
            // 人员信息，账号信息数据条数
            content.$nextTick(() => {
              content.$refs.accounttableData.total = content.accountData.total || 0
              content.$refs.personnetableData.total = content.personnelData.total || 0
              content.tipValidate()
            })
          }
        }
      }
    },
    {
      label: '法人代表：',
      prop: 'legalRepresentative',
      rules: [{
        required: true,
        title: '法人代表',
        validator: validatePassName,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '成立时间：',
      prop: 'foundTime',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: 'el-date-picker',
      rules: [{ required: true, validator: validatefoundTime, trigger: 'blur' }],
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '所在城市：',
      prop: 'locationCity',
      rules: [{ required: true, validator: validateLocationCity, trigger: ['blur', 'change'] }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: SupplierCascader,
      attrs: {
        addressvalue: content.baseQueryParas,
        type: 'add',
        placeholder: '请选择',
        address: getDictLists('ZDZD0013')
      }
    },
    {
      label: '公司电话：',
      prop: 'phoneNo',
      rules: [{
        required: true,
        validator: validatePhone,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    }, {
      label: '统一社会信用代码：',
      prop: 'creditCode',
      rules: [{
        required: true,
        validator: validatecreditCode,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        maxlength: 18,
        placeholder: '请输入'
      }
    }, {
      label: '公司地址：',
      prop: 'address',
      rules: [{
        required: false,
        message: '请输入公司地址',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 10, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '公司传真：',
      prop: 'fax',
      rules: [{
        required: false,
        message: '请输入',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        maxlength: 13,
        placeholder: '请输入'
      }
    }, {
      label: '工商注册日期：',
      prop: 'registerTime',
      rules: [{
        required: true,
        validator: validateregisterTime,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '注册资金(元)：',
      prop: 'registerMoney',
      tag: Amountinput,
      rules: [{
        required: true,
        message: '请输入注册资金',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入',
        type: 'G'
      }
    }, {
      label: '实控人：',
      prop: 'actualController',
      rules: [{
        required: false,
        message: '请输入实控人',
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '简称：',
      prop: 'enterpriseShortName',
      rules: [{
        required: true,
        validator: validataEnterpriseShortName,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '简码：',
      prop: 'enterpriseShortCode',
      rules: [{
        required: true,
        validator: validataEnterpriseShortCode,
        trigger: ['change', 'blur']
      }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入',
        maxlength: 5
      },
      on: {
        input (data) {
          console.log(data.toUpperCase(), 111)
          content.baseQueryParas.enterpriseShortCode = content.baseQueryParas.enterpriseShortCode.toUpperCase()
        }
      }
    }

  ]
}
// esa信息
export const esacFromConfig = (content) => {
  return [{
    label: '序号',
    prop: 'index',
    width: '80px'
  }, {
    label: '所属区域',
    prop: 'area',
    'show-overflow-tooltip': true
  }, {
    label: '是否直营',
    prop: 'isDirect',
    formatter: row => {
      return parseInt(row.isDirect) === 0 ? '否' : '是'
    }
  }, {
    label: '实际承包人姓名',
    prop: 'contractor_name'
  }, {
    label: '供应商类型',
    prop: 'enterpriseKind'
  }, {
    label: '供应商分类',
    prop: 'enterpriseType'
  },
  {
    label: '供应商等级',
    prop: 'enterpriseLevel'
  }, {
    label: '供应商分档',
    prop: 'enterpriseClassify'
  }, {
    label: '初始注册时间',
    prop: 'firstRegistTime',
    formatter: row => {
      return formatDate(row.firstRegistTime, 'YY-MM-DD')
    }
  }, {
    label: '公司规模',
    prop: 'enterpriseScale',
    'show-overflow-tooltip': true
  }, {
    label: '城市公司',
    prop: 'city',
    'show-overflow-tooltip': true,
    formatter: row => {
      return content.cityFormat(row.city)
    }
  }, {
    label: '企业简介及业务范围',
    prop: 'synopsis',
    'show-overflow-tooltip': true

  }, {
    label: '承包工程范围',
    prop: 'projectRange',
    'show-overflow-tooltip': true
  }]
}
export const qualificationscolumns = (content) => {
  return [{
    label: '资质编号',
    prop: 'code',
    width: '80px'
  },
  {
    label: '资质名称',
    prop: 'financingCode',
    'show-overflow-tooltip': true
  },
  {
    label: '资质等级',
    prop: 'applyDate'
  },
  {
    label: '资质有效期',
    prop: 'productType',
    'show-overflow-tooltip': true
  }
  ]
}
// 账户信息
export const accountcolumns = (content) => {
  return [
    {
      label: '账户类型',
      text: '选择',
      prop: 'accountType',
      HiddenOverflow: true
    },
    {
      label: '户名',
      prop: 'account',
      HiddenOverflow: true
    },
    {
      label: '开户行',
      prop: 'bankName',
      HiddenOverflow: true
    },
    {
      label: '账号',
      prop: 'accountNo',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      minWidth: '155px'
    }
  ]
}
// 人员信息
export const personnelcolumns = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      label: '姓名',
      prop: 'personnerName',
      HiddenOverflow: true
    },
    {
      label: '身份证',
      prop: 'idCard',
      HiddenOverflow: true

    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      HiddenOverflow: true
    },
    {
      label: '职位',
      prop: 'dutiesName',
      HiddenOverflow: true

    },
    {
      label: '家庭地址',
      prop: 'address',
      HiddenOverflow: true

    },
    {
      label: '人员类别',
      prop: 'dictId',
      HiddenOverflow: true
    },
    // {
    //   label: '是否EAS同步',
    //   prop: 'easUser',
    //   HiddenOverflow: true,
    //   formatter: row => {
    //     return row.easUser === 0 || !row.easUser ? '否' : '是'
    //   }
    // },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 人员关系子表格信息
export const personnelRelationColumns = (content) => {
  return [

    {
      label: '姓名',
      prop: 'personnalName',
      HiddenOverflow: true
    },
    {
      label: '身份证',
      prop: 'idCard',
      HiddenOverflow: true

    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      HiddenOverflow: true
    },
    {
      label: '地址',
      prop: 'address',
      HiddenOverflow: true
    },
    {
      label: '关系',
      prop: 'contactType',
      HiddenOverflow: true,
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType)
        return obj ? obj.dictName : '-'
      }
    },

    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 附件信息
export const filefromconfig = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      label: '序号',
      prop: 'index',
      HiddenOverflow: true
    },
    {
      label: '文件类型',
      prop: 'configItemName',
      HiddenOverflow: true

    },
    {
      label: '操作',
      prop: 'action',
      HiddenOverflow: true

    }
  ]
}

export const filefromtype = (content) => {
  return [{
    filetype: '营业执照',
    prop: 'filetype',
    configItemCode: 'WSF_FILE_0022'
  }, {
    filetype: '公司章程',
    prop: 'WSF_FILE_0026'
  },
  {
    filetype: '法定代表人身份证',
    prop: 'WSF_FILE_0025'
  }, {
    filetype: '实际控制人身份证',
    prop: 'WSF_FILE_0026'
  }, {
    filetype: '项目负责人身份证',
    prop: 'financingCode'
  }, {
    filetype: '纳税申报表',
    prop: 'financingCode'
  }, {
    filetype: '审计报告',
    prop: 'financingCode'
  }, {
    filetype: '在建工程明细',
    prop: 'financingCode'
  }, {
    filetype: '完税证明',
    prop: 'financingCode'
  }, {
    filetype: '公司征信报告',
    prop: 'financingCode'
  }, {
    filetype: '银行流水',
    prop: 'financingCode'
  }, {
    filetype: '企业调查问卷',
    prop: 'financingCode'
  }, {
    filetype: '个人征信报告',
    prop: 'financingCode'
  }, {
    filetype: '担保人身份证',
    prop: 'financingCode'
  }, {
    filetype: '担保人简历',
    prop: 'financingCode'
  }, {
    filetype: '担保人资产证明',
    prop: 'financingCode'
  }, {
    filetype: 'ERP授权书',
    prop: 'WSF_FILE_0068'
  }, {
    filetype: '担保人资产清单',
    prop: 'financingCode'
  }]
}
/**
 * 侧边菜单
 * @returns {[{label: string, id: number}, {label: string, id: number}, {label: string, id: number}, {label: string, id: number}, {label: string, id: number}, null]}
 */
export const sideMenu = (content) => {
  return [
    {
      label: '基本信息',
      id: 0
    },

    {
      label: '账户信息',
      id: 1
    },
    {
      label: '人员信息',
      id: 2
    },
    {
      label: '文件信息',
      id: 3
    },

    {
      label: '财务信息',
      id: 4
    },
    {
      label: '诉讼信息',
      id: 5
    },
    {
      label: '股权结构',
      id: 6
    }
  ]
}
export const fileList = (content) => {
  return [
    {
      label: '文件名称',
      prop: 'fileName'

    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
