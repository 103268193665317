<template>
  <div class="flex">
    <!--    检测内容-->
    <el-input
      :placeholder="placeholder"
      v-model="value"
      class="input-with-select"
      clearable
    >
    </el-input>
    <!--    检测按钮-->
    <BaseButton label="检测" class="btn" @click="testing"></BaseButton>

    <!--    检测成功提示-->
    <div class="tip" v-if="isTest !== '' && !isTest">
      <i class="el-icon-error" style="color:#FF4141;"></i>
      <span> &nbsp;检测失败，请核实企业名称后再检测</span>
    </div>
    <!--    检测失败提示-->
    <div class="tip" v-if="isTest !== '' && isTest">
      <i class="el-icon-success" style="color:#1ed06e;"></i>
      <span> &nbsp;检测成功</span>
    </div>

  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { supplierApi } from '@/api/companyApi'

export default {
  name: 'supperlierTesting',
  components: { BaseButton },
  props: {
    // 输入框占位
    placeholder: {
      type: String,
      default: ''
    },
    // 供应商名称
    enterpriseName: {
      type: String,
      default: () => ''
    },
    // 供应商id
    keyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: '',
      companyInfo: {},
      isTest: '', // 检测状态,
      id: ''
    }
  },
  watch: {
    enterpriseName (val) {
      this.value = val
    },
    value (val) {
      this.$emit('input', val)
    },
    keyId (val) {
      this.id = val || ''
    }
  },

  methods: {
    /**
     * 供应商检测
     * @param e
     */
    testing (e) {
      if (this.value === '') {
        this.$message.error('请输入企业名称')
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]/gi.test(this.value)) {
        this.$message.error('不能输入特殊符号')
        return
      }
      supplierApi.tyceas({
        supplierName: this.value,
        operatorId: 1,
        keyId: this.id,
        enterpriseLabel: 2
      })
        .then((res) => {
          // if (!res.data?.companyInfo?.registerNumber) {
          //   this.isTest = false
          //   return this.isTest
          // } else {
          //   this.isTest = true
          //   this.$emit('getSupplier', res.data)
          // }
          if (JSON.stringify(res.data) !== '{}') {
            if (JSON.stringify(res.data.companyInfo) !== '{}' || typeof res.data.companyInfo !== 'undefined') {
              if (res.data.companyInfo.registerNumber || res.data.companyInfo.creditCode) {
                this.isTest = true
                this.$emit('getSupplier', res.data)
              } else {
                this.isTest = false
              }
            }
          } else {
            this.isTest = false
            return this.isTest
          }
        })
        .catch(res => {
          console.log(res)
          this.isTest = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .input-with-select {
    width: 300px;
  }

  .btn {
    margin-left: 20px;
  }

  .tip {
    color: #38373a;
    font-size: 14px;
    flex: 1;
    margin-left: 20px;
    white-space: nowrap;
  }
}
</style>
